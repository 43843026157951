._90deg {
    transform: rotate(-90deg);
    position: absolute;
    left: 3.38889vw;
    top: 50%;
    transform-origin: 0 0;
    z-index: 9999;

    a {
        position: relative;
        left: -57px;
    }
}

.site-logo {
    z-index: 9999;
}

.prev-slide-btn ,
.next-slide-btn {
    bottom: 0;
    margin: auto;

    &:hover {
        cursor: pointer;
    }
}

.site-nav {
    position: absolute;
    left: 3.38889vw;
    bottom: 2.66667vw;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    z-index: 999;
}

.numberOne {
    margin-left: -39%;
    margin-top: -80%;
    opacity: 0.07;
}

.theInvite {
    left: 45vw;
}

.images {
    margin-top: -20%;
}

.opacity-07 {
    opacity: .07;
}

.centered {
    left: 45%;
}

.reception {
    left: 80%;
}