@font-face {
  font-family: 'Freight Big Pro Medium';
  src: url('../fonts/freight-big-pro-medium.eot') format('eot'),
       url('../fonts/freight-big-pro-medium.ttf') format('ttf'),
       url('../fonts/freight-big-pro-medium.woff') format('woff'),
       url('../fonts/freight-big-pro-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Freight Big Pro Bold';
  src: url('../fonts/FreightBigPro/FreightBigProBold-Regular.woff2') format('woff2'),
      url('../fonts/FreightBigPro/FreightBigProBold-Regular.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Freight Big Pro Black';
  src: url('../fonts/FreightBigPro/FreightBigProBlack-Regular.woff2') format('woff2'),
      url('../fonts/FreightBigPro/FreightBigProBlack-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Koya Sans';
  src: url('../fonts/KoyaSans/KoyaSans-Regular.woff2') format('woff2'),
      url('../fonts/KoyaSans/KoyaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



@tailwind base;
@tailwind components;

@import "./components/global.scss";

@tailwind utilities;

.is-mobile {
  .h-screen {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
}

.bg-apricot {
  background: #FFD0B1;
}
.bg-sienna {
  background: #D87F4D;
}
.bg-gossamer {
  background: #00997F;
}
.bg-turquoise {
  background: #31CDD0;
}
.bg-persiangreen {
  background: #039EA4;
}